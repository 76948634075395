

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  background-image: url('bg.png'); /* Adjusted path */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Fullscreen overlay styling */
.Overlay {
  position: fixed; /* Position relative to the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* 100% of viewport width */
  height: 100vh; /* 100% of viewport height */
  background-color: rgb(255, 255, 255); /* Semi-transparent background */
  color: white; /* Text color */
  display: flex; /* Center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  transition: opacity 1.5s ease; /* Smooth transition effect */
  opacity: 1;
  z-index: 9999; /* Very high z-index to ensure it is in front */
}

.App {
  text-align: center;
  width: 100%;
}

.App-header {
  margin-top: 20px; /* Add some margin at the top to ensure visibility */
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 300px;
  height: auto;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnails {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Ensure thumbnails are fully visible */
  
}

.thumbnails a img {
  width: 120px;
  height: 120px;
  margin: 0 5px;
}

.container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; 
  position: relative;
}

.model-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.model-container {
  padding: 0;
  height: 600px;
  width: 1500px;
  background-color: transparent; /* No background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-models {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 10px;
  padding: 10px;
  height: 600px; /* Increase height to provide more vertical space */
  width: 300px;  /* Width remains as you defined */
  background: #000000;
}

.side-models.left {
  left: 0;
  margin-left: 0px; /* Adjust as needed */
  background: transparent;
}

.side-models.right {
  right: 0;
  margin-right: 0px; /* Adjust as needed */
  background: transparent;
}

.side-models div {
  margin: 10px 0;
}
